.canvas {
  position: absolute;
  width: 100vw;
  inset: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 3s;
  transition-timing-function: var(--bezierFastoutSlowin);

  &[data-visible='true'] {
    opacity: 1;
  }
}
